import React, { useContext,useEffect,useState } from 'react';
import AuthContext from '../context/AuthContext';
import axios from 'axios'; // Add this import statement
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const { user, logout } = useContext(AuthContext);
  const [pdfList, setPdfList] = useState([]);

  useEffect(() => {
    const fetchPdfList = async () => {
      try {
        const token = localStorage.getItem('auth_token');

        const response = await axios.get("http://localhost:8000/api/pdfs", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPdfList(response.data);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      }
    };

    fetchPdfList();
  }, []);

  return (
    <>
    <div>
      <h1>Welcome, {user?.name}</h1>
      <button onClick={logout}>Logout</button>
    </div>
    <div>
      <h2>Available PDF Files</h2>
      <ul>
        {pdfList.map((pdf) => (
          <li key={pdf.id}>
            <Link to={`/pdf/${pdf.id}`}>{pdf.file_name}</Link>
          </li>
        ))}
      </ul>
    </div>
    </>
    
  );
};

export default Dashboard;