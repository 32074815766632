import { useEffect, useRef, useState } from "react";
import axios from "axios";

export default function PdfViewerComponent({ document, pdfId, fetchFormFields }) {
  const containerRef = useRef(null);
  const [pdfInstance, setPdfInstance] = useState([]); // State to hold focused annotations

  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;

    (async function () {
      PSPDFKit = await import("pspdfkit");
      const pspdfkitOptions = {
        container,
        document,
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        instantJSON:JSON.parse(fetchFormFields)
      };

      await PSPDFKit.load(pspdfkitOptions).then(async (instance) => {
        // Handle annotation focus
        instance.addEventListener("annotations.focus", function (annotationFocusEvent) {
          const newAnnotation = annotationFocusEvent.annotation.toJS();
          console.log("Focused Annotation:", newAnnotation);

          setPdfInstance((prevInstance) => {
            const updatedInstance = [...prevInstance, {
              id: newAnnotation.id,
              note: newAnnotation.note,
              boundingBox: newAnnotation.boundingBox,
            }];
            console.log("Updated pdfInstance on focus:", updatedInstance);
            return updatedInstance;
          });
        });

        // Handle annotation blur
        instance.addEventListener("annotations.blur", function (annotationBlurEvent) {
          const blurredAnnotationId = annotationBlurEvent.annotation.id;
          console.log("Blurred Annotation ID:", blurredAnnotationId);

          setPdfInstance((prevInstance) => {
            const updatedInstance = prevInstance.filter(annotation => annotation.id !== blurredAnnotationId);
            console.log("Updated pdfInstance on blur:", updatedInstance);
            return updatedInstance;
          });
        });

        // Add custom toolbar items
        instance.setToolbarItems((items) => [
          ...items,
          { type: "form-creator" }, // Ensure this type is supported by your PSPDFKit version
          {
            type: "custom",
            id: "save-form-fields",
            title: "Save",
            onPress: () => saveFormFields(instance),
          },
        ]);

        // Fetch and repopulate form fields when the instance is loaded
       
      });
    })();

    const saveFormFields = async (instance) => {
      try {
        const formFields = await instance.exportInstantJSON();
        console.log("Exported Instant JSON:", formFields);

        await sendFormDataToServer(JSON.stringify(formFields));
      } catch (error) {
        console.error("Error saving form fields:", error);
      }
    };

    const sendFormDataToServer = async (formData) => {
      const token = localStorage.getItem('auth_token');
      const serverUrl = `http://localhost:8000/api/pdf/update-metadata/${pdfId}`;
      try {
        const response = await axios.post(
          serverUrl,
          { metadata: formData }, // Request body
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json', // Ensure correct content type
            },
          }
        );
        if (response.status === 200) {
          console.log("Form fields updated successfully");
        } else {
          console.error("Failed to update form fields");
        }
      } catch (error) {
        console.error("Error updating form fields:", error);
      }
    };

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [document, pdfId, fetchFormFields]);

  return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />;
}