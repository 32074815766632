import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const ProtectedRoute = ({ component: Component }) => {
  const { user, loading } = useContext(AuthContext);

  // While loading, don't render anything yet
  if (loading) return <div>Loading...</div>;

  // If user is authenticated, render the component
  return user ? Component : <Navigate to="/login" />;
};

export default ProtectedRoute;