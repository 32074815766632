import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import PdfViewer from './components/PdfViewer';

import Register from './components/Register';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<ProtectedRoute component={<Dashboard />} />} />
          <Route path="/register" element={<Register />} />
          
          
          {/* Dynamic route for loading PDF based on ID */}
          <Route path="/pdf/:id" element={<ProtectedRoute component={<PdfViewer />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;