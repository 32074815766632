import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PdfViewerComponent from './PdfViewerComponent';

function PdfViewer() {
  const { id } = useParams(); // Get the PDF id from the URL
  const [pdfUrl, setPdfUrl] = useState(null);
  const [formFields, setFormFields] = useState([]); // State to hold form fields
  const [selectedFile, setSelectedFile] = useState(null); // To store the selected file

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await axios.get(`http://localhost:8000/api/pdf/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob', // Important to handle binary data
        });

        // Create a URL for the PDF file blob
        const fileUrl = URL.createObjectURL(response.data);
        console.log("PDF URL:", fileUrl);
        setPdfUrl(fileUrl);
      } catch (error) {
        console.error("Error fetching PDF URL:", error);
      }
    };

    const fetchFormFields = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        const response = await axios.get(`http://localhost:8000/api/pdf/metadata/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fields = response.data.data;
        setFormFields(fields);

        // Repopulate form fields
        
      } catch (error) {
        console.error("Error fetching form fields:", error);
      }
    };

    if (id) {
      fetchPdfUrl();
     fetchFormFields();
    }

    return () => {
      // Cleanup if needed
    };
  }, [id]);
// Handle file selection
const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]); // Store the selected file in state
};
// Handle file upload to the server
const handleFileUpload = async () => {
  if (!selectedFile) {
    alert("Please select a file before uploading.");
    return;
  }

  const formData = new FormData();
  formData.append('pdf_file', selectedFile);

  try {
    const token = localStorage.getItem('auth_token');
    const response = await axios.post('http://localhost:8000/api/pdf/upload', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // Required for file upload
      },
    });

    console.log("File uploaded successfully:", response.data);
    alert("File uploaded successfully!");
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Failed to upload file.");
  }
};
  return (
    <div className="App">
      <h3>Viewing PDF with ID: {id}</h3>
      <div className="file-upload">
        <h4>Upload a new PDF file</h4>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleFileUpload}>Upload File</button>
      </div>
      {pdfUrl ? (
        <PdfViewerComponent
          document={pdfUrl}
          pdfId={id}
          fetchFormFields={(formFields)} // Pass the function as a prop
        />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
}

export default PdfViewer;